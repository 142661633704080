import * as Sentry from '@sentry/react';
import { EnvNames, envFromHostname } from './env-from-hostname';

// initializes the global Sentry object
// according to the environment this browser is running
export function initializeSentry() {
  // get the environment from the hostname,
  // but allow overwrites using VITE_ENV_OVERWRITE variable
  // (specifically for playwright tests)
  const environmentName: string =
    import.meta.env.VITE_ENV_OVERWRITE || envFromHostname(window.location.hostname);

  Sentry.init({
    dsn: 'https://c9224f3b14e32d38ab7ebee6fd31151b@o4505744415850496.ingest.sentry.io/4505744417095680',
    environment: environmentName,
    integrations: (integrations) => {
      // add browser tracing in all environments
      integrations.push(
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'localhost',
            'https://cofund-preview.scaledagile.com',
            'https://cofund-dev.scaledagile.com',
            'https://cofund.scaledagile.com',
          ],
        })
      );

      // only add session replay feature in certain environments
      // to work around limit in sentry
      if (
        environmentName === EnvNames.PRODUCTION ||
        environmentName === EnvNames.PREVIEW ||
        environmentName === EnvNames.DEV
      ) {
        integrations.push(new Sentry.Replay());
      }
      return integrations;
    },
    // Performance Monitoring
    tracesSampleRate: 0.01, // Capture 1% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
